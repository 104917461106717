
























































// 處理左側導航菜單 icons
#app ul.navigation-main {
  li[data-menu-code] {
    a {
      display: block;
      position: relative;
      svg {
        visibility: hidden;
      }
    }
    a::before {
      content: '';
      background-position: center center;
      background-size: 3.2rem;
      background-repeat: no-repeat;
      overflow: visible;
      display: inline-block;
      position: absolute;
      left: 0.8rem;
      height: 100%;
      width: 2rem;
      transition: transform 0.25s ease;
      transform: translateX(0px);
    }
    a:hover::before {
      transition: transform 0.25s ease;
      transform: translateX(5px);
    }
  }
  > li > a::before {
    background-image: url("~@/assets/images/icons/icon-00.png");
  }
  li[data-menu-code=Com_Menu] > a::before {
    background-image: url("~@/assets/images/icons/icon-01.png");
  }
  li[data-menu-code=Fac_Menu] > a::before {
    background-image: url("~@/assets/images/icons/icon-02.png");
  }
  li[data-menu-code=Bed_Menu] > a::before {
    background-image: url("~@/assets/images/icons/icon-03.png");
  }
  li[data-menu-code=Sta_Menu] > a::before {
    background-image: url("~@/assets/images/icons/icon-04.png");
  }
  li[data-menu-code=Mem_Menu] > a::before {
    background-image: url("~@/assets/images/icons/icon-05.png");
  }
  li[data-menu-code=Gro_Menu] > a::before {
    background-image: url("~@/assets/images/icons/icon-06.png");
  }
  li[data-menu-code=Role_Menu] > a::before {
    background-image: url("~@/assets/images/icons/icon-07.png");
  }
  li[data-menu-code=Mon_Menu] > a::before {
    background-image: url("~@/assets/images/icons/icon-08.png");
  }
  li[data-menu-code=CallBell_Menu] > a::before {
    background-image: url("~@/assets/images/icons/icon-callbell.png");
    background-size: 110%;
  }
  li[data-menu-code=Notice_Menu] > a::before {
    background-image: url("~@/assets/images/icons/icon-notice.png");
    background-size: 105%;
  }
  li[data-menu-code=Admin_Menu] > a::before {
    background-image: url("~@/assets/images/icons/icon-admin.png");
    background-size: 105%;
  }
}

