//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

// source-1: src/@core/scss/base/bootstrap-extended/_variables.scss
// source-2: src/@core/scss/base/components/_variables.scss

$white: #fff;
//$gray-50: #f6f6f6;
//$gray-100: #babfc7; // $gray-lightest
//$gray-200: #ededed; // $gray-lighter
//$gray-300: #dae1e7; // $gray-light
//$gray-400: #636363;
//$gray-500: #adb5bd;
//$gray-600: #949ca4; // $gray
//$gray-700: #4e5154;
//$gray-800: #1e1e1e; // $gray-dark
//$gray-900: #2a2e30;
$gray-50: #f6f6f6;
$gray-100: #ddd; // $gray-lightest
$gray-200: #ccc; // $gray-lighter
$gray-300: #aaa; // $gray-light
$gray-400: #999;
$gray-500: #888;
$gray-600: #666; // $gray
$gray-700: #555;
$gray-800: #444; // $gray-dark
$gray-900: #333;
$black: #22292f; // 231f48 22292f


$purple: #76e; //$primary
$green: #28c76f; //$success
//$blue: #00cfe8; //$info
$blue: #69d; //$info
$orange: #e93; //$warning
$red: #ea5455; //$danger

$primary: $purple;
$secondary: #82868b;
$info: $blue;
$warning: $orange;
$light: $gray-50;
$dark: #4b4b4b;

$body-bg: #eee;
$body-color: #666;
$menu-dark-color: #ddd;

$font-weight-normal: normal;
$font-weight-bold: bold;
$font-weight-bolder: bold;

$font-family-sans-serif: source-han-sans-traditional, noto-sans, 'Microsoft YaHei', 'Montserrat', Helvetica, Arial, sans-serif;
$font-family-monospace: consolas, monospace;
//$font-family-monospace: source-han-sans-traditional, noto-sans, 'Microsoft YaHei', 'Montserrat', Helvetica, Arial, sans-serif;


/* WeHealth variables | start */
$inputBorderColor: #aaa;
$inputBorderColorDark: #888;
$placeholderColor: #999;
$placeholderColorDark: #7b7b7b;
$lightBackgroundColor: #f6f6f6;
$darkBackgroundColor: #384056;
/* WeHealth variables | end */

